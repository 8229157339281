// colors
export const colors = {
  // primary
  primary: '#FD7128',
  primary10: '#FFF3EA',
  primary20: '#FEE3D0',
  primary30: '#FEC6A3',
  primary40: '#FDAA75',
  primary50: '#FD8D4A',
  primary60: '#FD7128',
  primary70: '#F25400',
  primary80: '#AE3E00',
  primary90: '#652D0E',
  primary100: '#321705',
  // secondary
  secondary: '#FC1768',
  secondary10: '#FFE3ED',
  secondary20: '#FECFE0',
  secondary30: '#FFB3D0',
  secondary40: '#FD6DA3',
  secondary50: '#FC3E86',
  secondary60: '#FC1768',
  secondary70: '#CA1154',
  secondary80: '#970C3E',
  secondary90: '#65062A',
  secondary100: '#320314',

  // common
  white: '#fff',
  black: '#555',
  blackpure: '#000',
  red: '#F24242',
  red20: '#ffeadb',
  redsoft: '#e76969',
  green: '#27ae60',
  green20: '#E3FDF5',
  successgreen: '#00AF85',
  yellow: '#f1ca4b',
  yellowwarning: '#fffde4',
  border: '#e0e0e0',

  grey: '#bdbdbd',
  grey10: '#FAFAFA',
  grey20: '#F2F2F2',
  grey30: '#E0E0E0',
  grey40: '#BDBDBD',
  grey50: '#828282',
  grey60: '#4F4F4F',

  boxshadow: '0 1px 20px 0 rgba(155, 155, 155, 0.15)',
}

// fonts
export const fonts = {
  opensans: "'Open Sans', sans-serif",
  content: "'Avenir', sans-serif",
  title: "'Raleway', sans-serif",
  roboto: "'Roboto', sans-serif",
}

// breakpoints
export const breakpoints = ['768px', '992px', '1200px']
