import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

const Layout = React.lazy(() => import('./components/Layout/Layout'))
const Home = React.lazy(() => import('./routes/Home/Home'))
const MenuDetails = React.lazy(() => import('./routes/MenuDetails/MenuDetails'))
const Category = React.lazy(() => import('./routes/Category/Category'))
const Search = React.lazy(() => import('./routes/Search/Search'))
const Checkout = React.lazy(() => import('./routes/Checkout/Checkout'))
const Promo = React.lazy(() => import('./routes/Promo/Promo'))
const Payment = React.lazy(() => import('./routes/Payment/Payment'))
const CashPayment = React.lazy(() => import('./routes/CashPayment'))
const PaymentDetails = React.lazy(() =>
  import('./routes/Payment/PaymentDetails'),
)
const Finish = React.lazy(() => import('./routes/Payment/Finish'))
const OpenBill = React.lazy(() => import('./routes/OpenBill'))
const OpenBillDetail = React.lazy(() => import('./routes/OpenBill/Detail'))
const Invoice = React.lazy(() => import('./routes/Invoice'))

function Routes() {
  const element = useRoutes([
    {
      path: '/',
      element: <Layout Component={Home} />,
      children: [
        {
          path: ':slug',
          element: <Layout Component={Home} />,
        },
      ],
    },
    {
      path: ':slug/category',
      element: <Layout Component={Category} />,
    },
    {
      path: ':slug/search',
      element: <Layout Component={Search} />,
    },
    {
      path: ':slug/promo',
      element: <Layout Component={Promo} />,
    },
    {
      path: ':slug/menu/:menu_id',
      element: <Layout Component={MenuDetails} />,
    },
    {
      path: ':slug/menu/:menu_id/edit',
      element: <Layout Component={MenuDetails} />,
    },
    {
      path: ':slug/checkout',
      element: <Layout Component={Checkout} />,
    },
    {
      path: ':slug/checkout/payment',
      element: <Layout Component={Payment} />,
    },
    {
      path: ':slug/checkout/cash-payment/:order_id',
      element: <Layout watcher={false} Component={CashPayment} />,
    },
    {
      path: ':slug/checkout/payment/:payment_id',
      element: <Layout Component={PaymentDetails} />,
    },
    {
      path: ':slug/open-bill/:open_bill_id',
      element: <Layout Component={OpenBill} />,
    },
    {
      path: ':slug/open-bill/:open_bill_id/:open_bill_item_id',
      element: <Layout Component={OpenBillDetail} />,
    },
    {
      path: ':order_id/checkout/finish',
      element: <Layout Component={Finish} />,
    },
    {
      path: '/invoice/:order_id',
      element: <Layout Component={Invoice} />,
    },
    {
      path: '*',
      element: <Navigate to=":id" replace />,
    },
  ])
  return element
}

export default Routes
