import React from 'react'
// import { Image } from '../../assets/components/components'
// import images from '../../assets/images'

const PageLoading = () => {
  return (
    <div className="o-loading">
      {/* <Image src={images.logo?.logo} alt="Logo Trofi" /> */}
      {/* <div className="o-loading-progress">
        <div className="fill"></div>
      </div> */}
    </div>
  )
}

export default PageLoading
