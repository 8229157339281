import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en'
import id from './id'

const resources = {
  en,
  id,
}

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === "development",
})

export default i18n
