import React, { useEffect } from 'react'
import moment from 'moment'
import Geocode from 'react-geocode'
import { Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import './localization/i18n'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/font.css'
import './assets/scss/styles.scss'
import { colors } from './assets/styles/styles'
import PageLoading from './components/Spinner/PageLoading'
import Routes from './routes'

const pageloading = <PageLoading />

const googleapikey = process.env.REACT_APP_GOOGLE_API_KEY

const App = () => {
  const dispatch = useDispatch()

  const updateLocalData = (localStorageKey, reduxKey, value) => {
    dispatch({ type: 'update', [reduxKey]: value })
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({ data: value, last_update: moment() }),
    )
  }

  useEffect(() => {
    Geocode.setApiKey(googleapikey)

    dispatch({
      type: 'update',
      updateLocalData,
    })
  }, [])

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={pageloading}>
          <Routes />
        </React.Suspense>
      </BrowserRouter>

      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            padding: '10px 15px',
          },
          success: {
            style: {
              background: colors.primary,
              color: colors.white,
            },
          },
          error: {
            style: {
              background: colors.red,
              color: colors.white,
            },
          },
        }}
      />
    </>
  )
}

export default App
