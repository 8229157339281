// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    self_order: 'Self Order',
    no_menu: 'Belum ada menu',
    failed_bank: 'Gagal mendapatkan bank',
    notfound_bank: 'Bank tidak ditemukan',
    get_menu: 'Memuat menu...',
    notsupport_location: 'Lokasi tidak didukung',
    unavailble_location: 'Toko tidak tersedia',
    notactive_selforder: 'Self Order tidak aktif',
    expired_qr_tso: 'QR Self Order Kadaluarsa',
    wait_location_menu: 'Memuat menu dari toko, mohon menunggu',
    notsupport_browser:
      'Mohon maaf, browser anda tidak mendukung lokasi. Mohon gunakan browser / device lain.',
    notfound_restaurant:
      'Mohon maaf, toko tidak tersedia. Mohon pastikan link toko sudah benar.',
    inactive_self_order_link:
      'Mohon maaf, link Self Order saat ini sedang tidak aktif dan tidak dapat diakses',
    qr_new_self_order: 'Silahkan datang ke kasir untuk mendapatkan QR baru',
    notfound_data: 'Belum ada data',
    find_location: 'Cari Lokasi...',
    unavailble_some_menu: 'Ada menu yang tidak tersedia',
    update_promo: 'Perubahan pada promo',
    unavailable_menu: 'Menu tidak tersedia',
    unavailable_addons: 'Add Ons tidak tersedia',
    back_my_order: 'Kembali ke Pesanan Saya',
    update_order_to_contiinue:
      'Maaf, ada menu yang tidak tersedia. Mohon update pesanan untuk melanjutkan',
    change_promos: 'Ada beberapa update pada promo yang kamu dapatkan',
    unavailable_now_menu: 'Mohon maaf, menu ini sekarang sedang tidak tersedia',
    unavailable_some_addons:
      'Mohon maaf, ada add ons yang sedang tidak tersedia.\\nSilahkan pilih add ons lain atau tambahkan menu tanpa add ons',
    choose_product_promo: 'Pilih Promo Produk',
    limit_min_digitalpayment: 'Minimal pembayaran online payment 100 rupiah.',
    alert_normal_price:
      '*pembelian produk promo diatas maksimal qty pembelian akan dikenakan harga normal',
    limit_max_digitalpayment:
      'Maksimal pembayaran online payment 10.000.000 rupiah.',
    online_payment: 'Pembayaran online melalui bank / dompet digital kamu',
    pay_cashier: 'Bayar di Kasir',
    payment_done: 'Pembayaran langsung melalui kasir Restoran',
    code_verifiy:
      "Kode 4 digit telah dikirim ke <b>{phone_number || ''}</b> untuk verifikasi nomor telepon",
    notreceive_code: 'Tidak menerima kode?',
    resent_counter: 'Kirim ulang dalam {{counter}} detik',
    failed_load: 'Gagal memuat data',
    notsupperted_delivery: 'Pengiriman tidak didukung',
    out_of_range_location:
      'Mohon maaf, lokasi anda melebihi batas radius pengiriman dari restoran kami (&gt;25 km)',
    update_address: 'Ubah Alamat',
    payment_detail: 'Detail Pembayaran',
    delivery_fee: 'Biaya Pengantaran',
    payment_total: 'Total Pembayaran',
    today_restaurant_close: 'Hari ini restoran sedang libur',
    total_payment: 'Total Pembayaran',
    customer_detail: 'Detail Customer',
    type_pickup_order: 'Tipe Pengambilan Pesanan',
    secure_payment: '100% Secure Payment',
    order_summary: 'Ringkasan Pesanan',
    please_wait: 'Mohon menunggu',
    order_ready: 'Pesanan sudah siap',
    order_finished: 'Pesanan sudah selesai',
    order_received: 'Pesanan telah diterima',
    order_processed: 'Pesanan anda sedang diproses',
    order_pickup: 'Ambil pesanan atau tunggu pesananmu diantar ke meja',
    rescan_qr:
      'Silahkan melakukan scan ulang QR jika ingin membuat pesanan baru',
    link_duration: 'Link ini hanya bisa diakses dalam jangka waktu 1x24 jam',
    download_receipt: 'Download Struk',
    order_table: 'Order untuk table',
    success_void_order: 'Pesanan Berhasil Dibatalkan',
    void_orders: 'Pesanan yang dibatalkan',
    order_min: 'Min. order',
    max_purchase: 'Maks. pembelian',
    connection_lost: 'Koneksi terputus. Mohon periksa jaringan internet Anda',
    failed_try_again: 'Gagal memuat. Mohon coba kembali',
    expired_session: 'Sesi kamu telah habis. Silahkan login kembali',
    select_ewallet:
      'Buka aplikasi uang / dompet elektronik yang telah dipilih untuk pembayaran QRIS.',
    select_scan_qr: 'Tekan tombol scan QR.',
    insert_payment: 'Masukkan nominal pembayaran.',
    verification_payment: 'Verifikasi pembayaran dengan PIN / Password.',
    finished_transaction: 'Transaksi selesai.',
    pay_qris: 'Cara Bayar QRIS dengan',
    check_status_payment: 'Cek Status Pembayaran',
    pay_now: 'Buat Pembayaran',
    continue_payment: 'Lanjutkan Pembayaran',
    cancel_payment: 'Kamu yakin ingin membatalkan transaksi?',
    show_qr_cashier: 'Tunjukan QR Code pada kasir',
    complete_payment_cashier:
      'Silahkan lakukan pembayaran pada kasir sebelum pukul',
    to_process_order: 'supaya pesanan kamu dapat di proses.',
    order_add: 'Tambah Pesanan',
    receive_order_receipt: 'Email akan digunakan untuk mengirim nota pesanan',
    use_promo: 'Gunakan promo untuk dapat diskon',
    recheck_order: 'Yakin pesanan sudah sesuai?',
    cannot_edit_order: 'Kamu tidak dapat merubah pesanan setelah tahap ini',
    choose_bill: 'Pilih tipe bill',
    total_bill: 'Total Tagihan',
    pay_now2: 'Langsung Bayar',
    findMenu: 'Cari Menu',
    whatEatToday: 'Mau pesan apa?',
    recom: 'Rekomendasi',
    add_notes: 'Keterangan Tambahan',
    placeholder_add_notes: 'Contoh: info nomor meja / posisi kamu',
    open_bill: 'Open Bill',
    submit_order: 'Submit Pesanan',
    view_order: 'Lihat Pesanan Saya',
    required_name: 'Mohon isi nama',
    required_phone: 'Mohon isi no handphone',
    required_payment_method: 'Mohon pilih metode pengambilan pesanan',
    required_payment_type: 'Tidak ada jenis pembayaran yang aktif',
    view_less: 'Muat Lebih Sedikit',
    view_more: 'Muat Lebih Banyak',
    self_pickup: 'Ambil Sendiri',
    delivered_table: 'Diantar ke Meja',
    closing_soon_order: 'Hampir Tutup - Pesan sebelum',
    grand_total: 'Total Pesanan',
    need_help: 'Butuh bantuan terkait pesanan?',
    contact_us: 'Hubungi kami',
    notpaid: 'Belum Lunas',
    due: 'Jatuh Tempo',
    all_menu: 'Semua Menu',
    promo_product: 'Produk Promo',
    payment_method: 'Metode Pembayaran',
    how_pay: 'Cara Pembayaran',
    choose_method: 'Ubah Metode',
    choose_method_payment: 'Pilih Metode Pembayaran',
    required: 'Harus dipilih',
    max_purchase_qty: 'Max qty pembelian',
    required_select: 'Ada opsi yang harus dipilih',
    outside_op_hours: 'Restoran di luar jam operasional',
    order_total: 'Jumlah pesanan',
    applicable_add_fee:
      'Biaya tambahan mungkin berlaku untuk item tambahan tertentu',
    payment_processing: 'Memproses Pembayaran',
    payment_waiting: 'Menunggu Pembayaran',
    failed_payment: 'Pembayaran gagal dilakukan, mohon coba lagi',
    redirect_shortly: 'Kamu akan segera diarahkan. Mohon Menunggu',
    payment_via: 'Pembayaran via',
    choose_qris: 'Pilih metode QRIS di bawah ini:',
    success_payment: 'Pembayaran Berhasil',
    order_processed_wait:
      'Pesanan sedang disiapkan oleh pihak restoran. Mohon menunggu',
    your_order: 'pesanan anda.',
    transaction_id: 'ID Transaksi',
    view_order_sum: 'Lihat ringkasan pesanan',
    unavailable_promo: 'Promo yang belum dapat dipakai',
    add_item_promo: 'Tambah menu lain untuk dapat menikmati promo',
    notfound_menu: 'Menu tidak ditemukan',
    try_again: 'coba lagi',
    confirm: 'Konfirmasi',
    payment: 'Pembayaran',
    resend: 'Kirim ulang',
    promoAmount: 'Nominal Promo',
    name: 'Nama',
    phone: 'Nomor Handphone',
    invalid_email: 'Email tidak valid',
    description: 'Keterangan',
    table: 'Table',
    order_number: 'Nomor Order',
    my_order: 'Pesanan Saya',
    free: 'Gratis!',
    monday: 'Senin',
    tuesday: 'Selasa',
    wednesday: 'Rabu',
    thursday: 'Kamis',
    friday: 'Jumat',
    saturday: 'Sabtu',
    sunday: 'Minggu',
    cancel: 'Batal',
    edit: 'Ubah',
    notes: 'Catatan',
    pick_promo: 'Pilih Promo',
    pick_category: 'Pilih Kategori',
    optional: 'Opsional',
    choose: 'Pilih',
    order_max: 'Maks.',
    other_menu: 'Penawaran Menu Lainnya',
    update_order: 'Update Pesanan',
    find_other_menu: 'Cari Menu Lain',
    check_order_again: 'Cek Kembali Pesanan',
    open_hour: 'Jam Buka',
    pickup_order: 'Pengambilan Pesanan',
    close: 'Tutup',
    save: 'Simpan',
    delete_order: 'Hapus Pesanan',
    receive_name: 'Nama Penerima',
    email_address: 'Alamat Email',
    for_all_menu: 'Untuk semua menu',
    pay: 'Bayar',
    with: 'dengan',
    downloadBill: 'Download Nota',
  },
  day: {
    monday: 'Senin',
    tuesday: 'Selasa',
    wednesday: 'Rabu',
    thursday: 'Kamis',
    friday: 'Jumat',
    saturday: 'Sabtu',
    sunday: 'Minggu',
  },
}
