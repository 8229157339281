// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    self_order: 'Self Order',
    no_menu: 'No menu found',
    failed_bank: 'Failed get bank',
    notfound_bank: 'Bank not found',
    get_menu: 'Getting menu..',
    notsupport_location: 'Not support location',
    unavailble_location: 'Location unavailable',
    notactive_selforder: 'Self order not active',
    expired_qr_tso: 'Self order QR expired',
    wait_location_menu:
      "We're loading restaurant menu, please wait for a moment",
    notsupport_browser:
      'You browser does not support location. Please use other browsers to continue.',
    notfound_restaurant: 'Restaurant not found. Please re-check your URL link.',
    inactive_self_order_link:
      "We're sorry, Self Order link is currently inactive and cannot be accessed.",
    qr_new_self_order: 'Please go to the cashier to get a new Order QR',
    notfound_data: 'No data found',
    find_location: 'Find location..',
    unavailble_some_menu: 'Some menu are not available at the moment.',
    update_promo: 'Updates in promotion',
    unavailable_menu: 'Menu unavailable',
    unavailable_addons: 'Add Ons unavailable',
    back_my_order: 'Back to my order',
    update_order_to_contiinue:
      'Sorry, there are unavailable menu. Please update your order to continue.',
    change_promos: 'There are some changes made in your promo',
    unavailable_now_menu: 'Sorry, this menu is unavailable.',
    unavailable_some_addons:
      'Sorry, some add-ons are not available. \\nPlease choose other addons or choose other menu.',
    choose_product_promo: 'Choose Product Promo',
    limit_min_digitalpayment: 'Minimum amount for digital payment is Rp100',
    alert_normal_price:
      '*normal price will be applied to promoted menu above max. quantity',
    limit_max_digitalpayment:
      'Maximum amount for digital payment is Rp10.000.000',
    online_payment: 'Online payment from your bank / ewallet',
    pay_cashier: 'Pay at Cashier',
    payment_done: "Payment done from restaurant's cashier",
    code_verifiy:
      "4 digit code has been sent to <b>{phone_number || ''}</b> to verify phone number",
    notreceive_code: "Haven't received code?",
    resent_counter: 'Resend in {{counter}} seconds',
    failed_load: 'Failed to load data',
    notsupperted_delivery: 'Delivery is not supported',
    out_of_range_location:
      "We're sorry, your location is outside of our delivery range (&gt;25 km)",
    update_address: 'Update address',
    payment_detail: 'Payment detail',
    payment_total: 'Total Payment',
    today_restaurant_close: 'Today the restaurant is closed',
    total_payment: 'Total Payment',
    delivery_fee: 'Delivery Fee',
    customer_detail: 'Customer Detail',
    type_pickup_order: 'Pickup Order Type',
    secure_payment: '100% Secure Payment',
    order_summary: 'Order Summary',
    please_wait: 'Please wait',
    order_ready: 'Order is ready',
    order_finished: 'Order is finished',
    order_received: 'Order has been received',
    order_processed: 'Your order is being processed',
    order_pickup:
      'Pick up order or wait for your order to be delivered to table',
    rescan_qr: 'Please re-scan QR if you want to create new order',
    link_duration: 'This link can only be accessed in 1x24hours',
    download_receipt: 'Download Receipt',
    order_table: 'Order for table',
    success_void_order: 'Void order success',
    void_orders: 'Void Orders',
    order_min: 'Min. order',
    max_purchase: 'Max. purchase',
    connection_lost: 'Connection lost. Please check your internet connection.',
    failed_try_again: 'Failed to load data. Please try again.',
    expired_session: 'Your session has expired. Please relogin.',
    select_ewallet: 'Open your ewallet apps as selected.',
    select_scan_qr: 'Select button Scan QR',
    insert_payment: 'Insert payment amount',
    verification_payment: 'Payment verification with PIN / Password',
    finished_transaction: 'Transaction finished',
    pay_qris: 'How to pay QRIS with',
    check_status_payment: 'Check Payment Status',
    pay_now: 'Pay Now',
    continue_payment: 'Continue Payment',
    cancel_payment: 'Are you sure you want to cancel this transaction?',
    show_qr_cashier: 'Show QR to cashier',
    complete_payment_cashier: 'Please complete payment at cashier before',
    to_process_order: 'to process your order',
    order_add: 'Add Order',
    receive_order_receipt: 'You will receive order receipt through this email',
    use_promo: 'Use promo to get discounts',
    recheck_order: 'Recheck your orders?',
    cannot_edit_order: 'You cannot edit your orders after this step',
    choose_bill: 'Choose bill type',
    total_bill: 'Total Bill',
    pay_now2: 'Pay Now',
    findMenu: 'Search Menu',
    whatEatToday: 'What do you want to eat today?',
    recom: 'Recommendations',
    add_notes: 'Other Notes',
    placeholder_add_notes: 'Ex: table number',
    self_pickup: 'Self Pickup',
    delivered_table: 'Delivered to Table',
    open_bill: 'Open Bill',
    submit_order: 'Submit Order',
    view_order: 'View my Order',
    required_name: 'Please fill name',
    required_phone: 'Please fill phone number',
    required_payment_method: 'Please choose payment method',
    required_payment_type: 'No active payment type',
    view_less: 'View Less',
    view_more: 'View More',
    closing_soon_order: 'Closing Soon - Order before',
    grand_total: 'Grand Total',
    need_help: 'Need help?',
    contact_us: 'Contact us',
    notpaid: 'Not Paid Yet',
    due: 'Due',
    all_menu: 'All Menu',
    promo_product: 'Promo Product',
    payment_method: 'Payment Method',
    how_pay: 'How to pay',
    choose_method: 'Change method',
    choose_method_payment: 'Choose Payment Method',
    required: 'Required',
    max_purchase_qty: 'Max. Purchase qty',
    required_select: 'Required to select at least 1',
    outside_op_hours: 'Restaurant is currently outside operational hours',
    order_total: 'Total Order',
    applicable_add_fee:
      'Additional fee might be applicable for some additional items',
    payment_processing: 'Processing payment',
    payment_waiting: 'Waiting for payment',
    failed_payment: 'Payment failed, please try again',
    redirect_shortly: 'You will be redirected shortly, please wait',
    payment_via: 'Payment via',
    choose_qris: 'Choose QRIS method below',
    success_payment: 'Payment Success',
    order_processed_wait:
      'Order is being processed by restaurant, please wait.',
    your_order: 'your order',
    transaction_id: 'Transaction ID',
    view_order_sum: 'View order summary',
    unavailable_promo: 'Unavailable promo',
    add_item_promo: 'Add other item to get promo',
    notfound_menu: 'Menu not found',
    try_again: 'try again',
    confirm: 'Confirm',
    payment: 'Payment',
    resend: 'Resend',
    promoAmount: 'Promo Amount',
    name: 'Name',
    phone: 'Phone Number',
    invalid_email: 'Invalid Email',
    description: 'Description',
    table: 'Table',
    order_number: 'Order Number',
    my_order: 'My Order',
    free: 'Free!',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    cancel: 'Cancel',
    edit: 'Edit',
    notes: 'Notes',
    pick_promo: 'Pick Promo',
    pick_category: 'Pick Category',
    optional: 'Optional',
    choose: 'Choose',
    order_max: 'Max.',
    other_menu: 'Other Menu',
    update_order: 'Update Order',
    find_other_menu: 'Find Other Menu',
    check_order_again: 'Check Order Again',
    open_hour: 'Open Hours',
    pickup_order: 'Pickup Order',
    close: 'Close',
    save: 'Save',
    delete_order: 'Delete Order',
    receive_name: 'Receive Name',
    email_address: 'Email Address',
    for_all_menu: 'For all menu',
    pay: 'Pay',
    with: 'with',
    downloadBill: 'Download Bill',
  },
  day: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
}
