import { createStore } from 'redux'

const initialState = {
  ready: false,
  loading: false,

  success: {
    open: false,
    message: '',
    icon: '',
    buttonOnPress: () => {},
  },

  location: {
    latitude: -6.17543,
    longitude: 106.827083,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  },

  location_slug: '',
  store: {},

  search_histories: [],

  paymentType: {
    isOpenBill: false,
    isDirect: false,
  },

  operational_status: {
    open: true,
    active_today: true,
    start_hour: '',
    close_hour: '',
  },

  promos: {
    all: [],
    1: [], // general
    2: [], // products
    3: [], // bundling
    disabled: [], // for unusable promo, e.g out of the promo date / time, or min spend
  },
  cart_session_id: '',
  cart: [],
  checkout_token: '',

  checkout_form: {
    name: '',
    email: '',
    phone: '',
    notes: '',
    promo: '',
    payment: '',
    delivery_type: '',
  },

  calculations: {
    subtotal: 0,
    tax: 0,
    grand_total: 0,
    discount_amount: 0,
  },

  order_data: '',

  resetCart: false,
  refetchMenu: false,
  refetchCart: false,
  refetchPromo: false,
  refetchOrder: false,

  modalError: {
    type: '',
    callback: () => {},
  },
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'update':
      return { ...state, ...rest }

    case 'loading':
      return {
        ...state,
        loading: true,
      }

    case 'unloading':
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

const store = createStore(changeState)

export default store
